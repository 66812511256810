import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { titleCase } from "title-case";


// Comonent library
import 'semantic-ui-css/semantic.min.css' // Semantic UI component library: https://react.semantic-ui.com/
//// see https://react.semantic-ui.com/layouts/fixed-menu/ 
//// and https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/src/layouts/FixedMenuLayout.js for example
import { 
  Container,
  Card,
  Menu,
  Header,
  Image,
} from 'semantic-ui-react'

import HeaderBar from '../components/HeaderBar.js'
import FooterBar from '../components/FooterBar.js'


// styles


// data

// markup

// utils

const ci = require('case-insensitive')

const BlogPage = ({ location, data }) => {
  // see https://www.gatsbyjs.com/docs/location-data-from-props/
  const args = location.search; // === "" if no URL parameters are provided, === "?filter=nature" or similar otherwise
  const params = new URLSearchParams(args);
  let filter = params.get('filter'); // returns null for missing parameters
  const post_search = useStaticQuery(graphql`
  query FilterPosts {
    allMdx {
      nodes {
        frontmatter {
          title
          topics
          date
          image
          blurb
          published
        }
        slug
      }
    }
  }
  `)
  let posts = post_search.allMdx.nodes.map((node) => {return {slug: node.slug, ...node.frontmatter}}).filter((entry) => entry.published)
  posts.sort((a, b) => {if(a.date === b.date){return 0} else if(a.date < b.date){return 1} else {return -1}})
  let search_title = "All Blog Posts"

  if(filter != null){
    posts = posts.filter((p) => p.topics.some((t) => ci(t).equals(filter)));
    search_title = titleCase(filter) + " Blog Posts"
  }
  //let pstr = JSON.stringify(posts)
  

  return (
  <div>
  <header role="banner">
      <HeaderBar />
    </header>
    <main role="main">

    <Container text style={{ marginTop: '7em' }}>
      <Header as='h1'>{search_title}</Header>
      <Menu secondary>
        <Menu.Item>
          <Link to="/blog">All Posts</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/blog?filter=sci-tech">Sci & Tech</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/blog?filter=hobby">Creative Hobbies</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/blog?filter=nature">Nature & Gardening</Link>
        </Menu.Item>
      </Menu>

      <Card.Group>
      {posts.map((p) => (
        <Card>
          <Card.Content>
            <Image src={"/"+p.image} floated='left' size='mini' />
            <Card.Header><Link to={"posts/"+p.slug}>{p.title}</Link></Card.Header>
            <Card.Meta>
              <span className='date'>{new Date(p.date).toDateString()}</span>
            </Card.Meta>
            <Card.Description>
              {p.blurb}
            </Card.Description>
          </Card.Content>
        </Card>
      ))}
      </Card.Group>
      
      
    </Container>

    
    </main>
    <footer role="banner">
      <FooterBar />
    </footer>
    </div>
  )
}

export default BlogPage
